.es-button {
    &.styled {
        border: 0;
        border-radius: 25px;
        font-size: 1.4rem;
        font-weight: 500;
        margin: 0 8px;
        min-height: 40px;
        padding: 6px;
    }

    &.solid {
        @extend .styled;

        background: var(--background-color);
        border-radius: 25px;
        color: var(--color);
    }

    &.outline {
        @extend .styled;

        background: transparent;
        border: 2px solid var(--background-color);
        color: var(--color-text);
    }
    &.outline-mono {
        @extend .styled;

        background: transparent;
        border: 2px solid var(--background-color);
        color: var(--background-color);
    }

    &.clear {
        @extend .styled;

        background: transparent;
        color: var(--color);
    }
}
