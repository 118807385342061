:root {
  /** primary **/
  --color-primary: #3399ff;
  --color-primary-rgb: 51, 153, 255;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  // --color-primary-shade: #248af0;
  --color-primary-tint: #E1EFFE;

  /** primary reverse **/
  --color-primary-reverse: #ffffff;
  --color-primary-reverse-rgb: 255, 255, 255;
  --color-primary-reverse-contrast: #3399ff;
  --color-primary-reverse-contrast-rgb: 51, 153, 255;
  // --color-primary-shade: #248af0;
  // --color-primary-tint: #55aaff;


  /** success **/
  --color-success: #10B981;
  --color-success-rgb: 16, 185, 129;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 0, 0, 0;
  --color-success-tint: #D1FAE5;
  // --color-success-shade: #2cc1ab;

  /** warning **/
  --color-warning: #F59E0B;
  --color-warning-rgb: 245, 158, 11;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0, 0, 0;
  --color-warning-tint: #FEF3C7;
  --color-warning-text: #92400E;
  // --color-warning-tint: #fdf6b2;


  /** declined **/
  --color-declined: #F97316;
  --color-declined-rgb: 249, 115, 22;
  --color-declined-contrast: #000000;
  --color-declined-contrast-rgb: 0, 0, 0;
  --color-declined-tint: #FEECDC;
  // --color-warning-tint: #fdf6b2;


  /** danger **/
  --color-danger: #EF4444;
  --color-danger-rgb: 239, 68, 68;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-tint: #FEE2E2;
  // --color-danger-tint: #f15962;

  /** info **/
  --color-info: #428DFF;
  --color-info-rgb: 66, 141, 255;
  --color-info-contrast: #ffffff;
  --color-info-contrast-rgb: 255, 255, 255;
  --color-info-tint: #E1EFFE;

  /** cna **/
  --color-cna: #3399ff;
  --color-cna-rgb: 51, 153, 255;
  --color-cna-contrast: #ffffff;
  --color-cna-contrast-rgb: 0, 0, 0;
  --color-cna-tint: #E1EFFE;

  /** lpn **/
  --color-lpn: #15baa2;
  --color-lpn-rgb: 21, 186, 162;
  --color-lpn-contrast: #ffffff;
  --color-lpn-contrast-rgb: 0, 0, 0;
  --color-lpn-tint: #DEF7EC;
  // --color-lpn-tint: #2cc1ab;

  /** rn **/
  --color-rn: #ef4651;
  --color-rn-rgb: 239, 70, 81;
  --color-rn-contrast: #ffffff;
  --color-rn-contrast-rgb: 255, 255, 255;
  --color-rn-tint: #FEE2E2;
  // --color-rn-tint: #f15962;

  /** gna **/
  --color-gna: #FFC91C;
  --color-gna-rgb: 255, 201, 28;
  --color-gna-contrast: #ffffff;
  --color-gna-contrast-rgb: 255, 255, 255;
  --color-gna-tint: #FFFACE;

  /** tma **/
  --color-tma: #FFC91C;
  --color-tma-rgb: 255, 201, 28;
  --color-tma-contrast: #ffffff;
  --color-tma-contrast-rgb: 255, 255, 255;
  --color-tma-tint: #FFFACE;

  /** cmt **/
  --color-cmt: #FFC91C;
  --color-cmt-rgb: 255, 201, 28;
  --color-cmt-contrast: #ffffff;
  --color-cmt-contrast-rgb: 255, 255, 255;
  --color-cmt-tint: #FFFACE;

    /** cma **/
    --color-cma: #FFC91C;
    --color-cma-rgb: 255, 201, 28;
    --color-cma-contrast: #ffffff;
    --color-cma-contrast-rgb: 255, 255, 255;
    --color-cma-tint: #FFFACE;

  /** text **/
  --color-text-title: #1F2937;
  --color-text: #4B5563;
  --color-text-secondary: #6B7280;
  --color-text-placeholder: #9CA3AF;
  --color-text-contrast: #ffffff;
  --color-text-contrast-rgb: 255, 255, 255;

  /** border **/
  --color-border-base: #D1D5DB;
  --color-border-active-input: #9CA3AF;
  --color-border-selected: #3399FF;

  /** Background colors **/
  --color-background: #FFFFFF;
  --color-background-secondary: #E1EFFE;
  --color-background-shade: #F9FAFB;
  --color-background-shade-secondary: #F3F4F6;




  /** aditional **/

  --color-chat-btn: #E1EFFE;
  --color-chat-btn-contrast: #3399ff;

  --color-insta-purple:  #7E3AF2;
  --color-insta-purple-tint:  #EDEBFE;

  --color-new-red: #E74694;

  --color-secondary-stroke: #E6E6E6;

}