@import "./button.scss";

@import "./fonts.scss";
@import "./reset.scss";
@import "./variables.scss";
@import "./global/global.scss";
@import "ngx-spinner/animations/ball-scale-multiple.css";
@for $i from 1 through 10 {
    .m-t-#{$i * 5} {
        margin-top: 5px * $i;
    }

    .m-b-#{$i * 5} {
        margin-bottom: 5px * $i;
    }

    .m-l-#{$i * 5} {
        margin-left: 5px * $i;
    }

    .m-r-#{$i * 5} {
        margin-right: 5px * $i;
    }

    .p-t-#{$i * 5} {
        padding-top: 5px * $i;
    }

    .p-b-#{$i * 5} {
        padding-bottom: 5px * $i;
    }

    .p-l-#{$i * 5} {
        padding-left: 5px * $i;
    }

    .p-r-#{$i * 5} {
        padding-right: 5px * $i;
    }
}

html,
body,
div#app {
    font-family: $font-stack;
    font-size: 10px;
    overflow: hidden auto;

    a {
        cursor: pointer;
    }
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

img {
    object-fit: cover;
}

.RN {
    color: var(--color-rn);
}

.CNA {
    color: var(--color-cna);
}

.GNA {
    color: var(--color-gna);
}

.LNA {
    color: var(--color-cna);
}

.LPN {
    color: var(--color-lpn);
}

.STNA {
    color: var(--color-cna);
}

.QMA {
    color: var(--color-gna);
}

.TMA {
    color: var(--color-tma);
}

.CMT {
    color: var(--color-cmt);
}

.CMA {
    color: var(--color-cma);
}

.rn {
    color: var(--color-rn);
}

.lpn {
    color: var(--color-lpn);
}

.cna {
    color: var(--color-cna);
}

.gna {
    color: var(--color-gna);
}

.lna {
    color: var(--color-cna);
}

.stna {
    color: var(--color-cna);
}

.qma {
    color: var(--color-gna);
}

.tma {
    color: var(--color-tma);
}

.cmt {
    color: (var(--color-cmt));
}

.cma {
    color: (var(--color-cma));
}